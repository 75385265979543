<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> <div>系统试用申请</div> </template>
    </HeaderBox>

    <!-- 列表搜索条件 -->
    <div class="selects findItem">
      <a-select
        placeholder="选择状态"
        style="width: 200px;height: 29px;"
        v-model="status"
        allowClear
      >
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option :value="0"> 未处理 </a-select-option>
        <a-select-option :value="1"> 已处理 </a-select-option>
      </a-select>
      <a-select
        placeholder="选择申请系统"
        style="width: 200px;height: 29px;"
        v-model="systemName"
        allowClear
      >
        <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
        <a-select-option value="筛查诊疗系统"> 筛查诊疗系统 </a-select-option>
        <a-select-option value="卡罗来纳评估系统"> 卡罗来纳评估系统 </a-select-option>
      </a-select>
      <a-range-picker
        style="width: 220px"
        :placeholder="['申请开始时间','申请结束时间']"
        valueFormat="YYYY-MM-DD HH:mm:ss"
        showTime
        v-model="findtime"
      ></a-range-picker>
      <a-input v-model="orgName" placeholder="请输入机构名称" />
      <a-button type="primary" @click="search()">搜索</a-button>
      <a-button class="all_btn_border btnBorder" @click="reset">重置</a-button>
    </div>

    <!-- 列表 -->
    <a-table
      class="table-template"
      :rowKey="(item) => item.id"
      :loading="loadList"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: true }"
      @change="onPage"
      :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="mobile" slot-scope="text,row">
        {{ row.mobile }}
      </template>
      <template slot="status" slot-scope="text">
        {{ text == 1 ? '已处理' : "未处理" }}
      </template>

      <template slot="operationName" slot-scope="text">
        {{ text ? text : "--" }}
      </template>

      <template slot="operationTime" slot-scope="text">
        {{ text ? text : "--" }}
      </template>

      <template slot="operation" slot-scope="text, record">
        <div class="btn_router_link">
          <a @click="changeStatus(record)">更新状态</a>
        </div>
      </template>
    </a-table>

    <!-- 更新状态 -->
    <a-modal
      title="更新状态"
      :width="1000"
      :closable="false"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="visible = false"
      @ok="freeback"
      okText="确认"
    >
      <div class="modelTitle">申请信息</div>
      <div class="options">
        <div class="option">
          <p>姓名：</p>
          <a-input class="value" v-model="form.applyName" placeholder="请输入姓名" />
        </div>
        <div class="option">
          <p>所在地区：</p>
          <div class="value" style="line-height: 32px;">{{ form.address }}</div>
        </div>
        <div class="option">
          <p>联系电话：</p>
          <a-input class="value" v-model="form.mobile" placeholder="请输入联系电话" />
        </div>
        <div class="option">
          <p>绑定手机号：</p>
          <div class="value" style="line-height: 32px;">{{ form.mobileBk }}</div>
        </div>
        <div class="option">
          <p>申请系统：</p>
          <div class="value" style="line-height: 32px;">{{ form.systemName }}</div>
        </div>
        <div class="option">
          <p>从业人数：</p>
          <a-input-number class="value" id="inputNumber" v-model="form.practitionerNumber" :min="0" />
        </div>
        <div class="option">
          <p>申请时间：</p>
          <div class="value" style="line-height: 32px;">{{ form.createTime }}</div>
        </div>
        <div class="option">
          <p>孩子人数：</p>
          <a-input-number class="value" id="inputNumber" v-model="form.childNumber" :min="0" />
        </div>
        <div class="option">
          <p>机构名称：</p>
          <a-input class="value" v-model="form.orgName" placeholder="请输入机构名称" />
        </div>
        <div class="option">
          <p>评估工具：</p>
          <a-textarea
            class="value"
            v-model="form.source"
            placeholder="请输入评估工具"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </div>
      </div>
      
      <div class="modelTitle" style="margin-top: 20px;">修改状态</div>
      <div class="option">
        <p>请选择状态：</p>
        <a-select
          class="value"
          placeholder="请选择状态"
          style="width: 200px;height: 29px;"
          v-model="form.status"
        >
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option :value="0"> 未处理 </a-select-option>
          <a-select-option :value="1"> 已处理 </a-select-option>
        </a-select>
      </div>
      
      <div class="option" style="width: 100%;">
          <p>备注：</p>
          <a-textarea
            class="value"
            v-model="form.remark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </div>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import Province from "@/components/provinceCity";
const columns = [
  {
    title: "序号",
    align: "center",
    fixed: "left",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "applyName",
  },
  {
    title: "联系方式",
    align: "center",
    scopedSlots: { customRender: "mobile" }
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "orgName",
  },
  {
    title: "申请时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "申请系统",
    align: "center",
    dataIndex: "systemName"
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Province },
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      loadList: false,
      status: undefined, // 状态
      systemName: undefined, // 系统名称
      orgName: '', // 机构名称
      findtime: [], // 时间段

      // 更新状态弹框
      loading: false,
      confirmLoading: false,
      visible: false,
      form: {}
    }
  },
  created() {
    this.getManageList();
  },
  methods:{
    // 重置
    reset() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.status = undefined;
      this.systemName = undefined;
      this.orgName = '';
      this.findtime = [];
      this.getManageList();
    },

    // 更新状态弹框
    changeStatus(e) {
      this.visible = true;
      this.form = JSON.parse(JSON.stringify(e));
      this.form.address = this.form.province + this.form.city + this.form.county
    },

    // 获取发货省市区编码
    FunProvince(data) {
      console.log(data)
      this.form.province = data[0];
      this.form.city = data[1];
      this.form.area = data.length > 2 ? data[2] : "";

      this.form.address = data;
    },

    // 更新状态
    freeback() {
      if(!this.form.applyName) {
        return this.$message.warning('请输入姓名！')
      }else if(!this.form.mobile) {
        return this.$message.warning('请输入联系电话！')
      }else if(!this.form.orgName) {
        return this.$message.warning('请输入机构名称！')
      }
      let params = {
        address: '', // 详细地址
        applyName: this.form.applyName, // 申请人姓名
        childNumber: this.form.childNumber, // 孩子人数
        city: this.form.city, // 市
        county: this.form.county, // 县
        createTime: this.form.createTime, 
        dealNote: this.form.dealNote, // 后台处理说明
        id: this.form.id, // 申请id
        mobile: this.form.mobile, // 申请人手机号
        mobileBk: this.form.mobileBk, // 备用联系手机号
        orgName: this.form.orgName, // 申请机构名称
        practitionerNumber: this.form.practitionerNumber, // 从业人数
        province: this.form.province, // 省
        remark: this.form.remark, // 用户备注
        source: this.form.source, // 评估工具内容
        status: this.form.status, // 状态 0-未处理 1-已处理
        systemName: this.form.systemName, // 申请使用系统名称
        userId: this.form.userId, // 用户id
        modifier: this.$store.state.userInfo.id
      }
      this.confirmLoading = true;
      this.$ajax({
        url: "/hxclass-management/systemApply/apply",
        method: "post",
        params: params
      }).then((res) => {
        this.confirmLoading = false;
        this.visible = false;
        if (res.code == 200 && res.success) {
          this.$message.success('操作成功！')
          this.getManageList()
        }else{
          this.$message.error(res.message);
        }
      });
    },

    // 搜索
    search() {
      this.pageNumber = 1;
      this.getManageList();
    },

    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },

    // 获取列表数据
    getManageList() {
      this.loadList = true;
      this.$ajax({
        url: "/hxclass-management/systemApply/list",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          orgName: this.orgName,
          status: this.status,
          start: this.findtime[0] ? this.findtime[0] : "",
          end: this.findtime[1] ? this.findtime[1] : "",
          systemName: this.systemName,
        },
      }).then((res) => {
        this.loadList = false;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
  .selects {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-end;
  }
  .findItem {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    // /deep/.ant-form-item-control-wrapper{
    //   width: 240px !important;
    //   height: 29px !important;
    // }
    /deep/.ant-input {
      width: 200px !important;
      height: 29px !important;
      font-size: 12px !important;
      color: #999999 !important;
      line-height: 14px !important;
      margin-right: 20px;
      margin-bottom: 0 !important;
    }
    .ant-btn {
      margin-right: 24px;
    }
    .ant-btn:last-child {
      margin-right: 0;
    }
    /deep/.ant-select-selection--single{
      height: 29px;
      font-size: 12px !important;
      color: #999999 !important;
      line-height: 14px !important;
      margin-right: 20px;
      margin-bottom: 0 !important;
    }
  }
  .avatar-uploader {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .ant-upload-text {
      font-size: 12px;
      color: #666666;
      line-height: 14px;
    }
  }
  .modelTitle{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .option{
    width: 50%;
    padding: 0 50px;
    display: flex;
    margin-top: 20px;
    p{
      line-height: 32px;
      width: 100px;
      text-align: right;
    }
    .value{
      flex: 1;
    }
  }
  .option:first-child{
    margin-top: 0;
  }
  .option:nth-child(2){
    margin-top: 0;
  }
</style>